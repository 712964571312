import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/elves-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1989 Christmas horror movie, Elves"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 25</h1>
                    <h2>Elves</h2>
                    <h3>November 13, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Jeff Mandel // <b>Starring:</b> Dan Haggerty &amp; Julie Austin</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/25-Elves-e2brm1u" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 25, Elves"></iframe>
                        </div>
                        <p><Link to="/transcripts/elves">Read the episode transcript</Link></p>
                        <p>As we near the holiday season, Bryan and Dave darken your doorstep with the low-budget howling madness of 1989's Elves, a movie that under-delivers on elves and over-delivers on incest. If you've ever wondered what Jim Wynorski's version of Gremlins would be this one lands pretty close to the mark as Dan "Grizzly Adams" Haggerty attempts to uncover the truth about a murderous elf creature and protect her from Nazis and her own sadistic mother while chain smoking.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=GLo795JFfpg" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=GLo795JFfpg</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/blood-rage">Next Episode</Link></li>
                        <li><Link to="/episodes/the-watcher-in-the-woods">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)